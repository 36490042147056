:root {
  --anim1-value: 0%;
  --anim2-value: 0%;
  --anim3-value: 0%;
}

.container {
  height: 50vh;
  width: 80vw;
  margin: 0 auto;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  padding: 0 1.2rem;
  position: relative;
}

.bar-container {
  height: 90%;
  width: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 9.6rem;
}

.bar-background {
  background-color: lightgrey;
  height: 95%;
  width: 1.5rem;
  border-radius: 100px;
  display: flex;
  align-items: flex-end;
}

.bar-text {
  font-size: 3rem;
  font-weight: 600;
}

.bar-filler-1 {
  background-color: #00bff2;
  height: var(--anim1-value);
  width: 100%;
  border-radius: 100px;
}

.animate-bar1 {
  animation-duration: 2s;
  animation-name: slideIn-1;
}

@keyframes slideIn-1 {
  from {
    height: 0;
  }
  to {
    height: var(--anim1-value);
  }
}

.num-container {
  height: 95%;
  display: flex;
  align-items: flex-end;
}

.bar-num-1 {
  padding: 0 1rem;
  color: black;
  height: var(--anim1-value);
}

.bar-filler-2 {
  background-color: #00bff2;
  height: var(--anim2-value);
  width: 100%;
  border-radius: 100px;
}

.animate-bar2 {
  animation-duration: 2s;
  animation-name: slideIn-2;
}

.bar-num-2 {
  padding: 0 1rem;
  color: black;
  height: var(--anim2-value);
}

@keyframes slideIn-2 {
  from {
    height: 0;
  }
  to {
    height: var(--anim2-value);
  }
}

.bar-filler-3 {
  background-color: #00bff2;
  height: var(--anim3-value);
  width: 100%;
  border-radius: 100px;
}

.animate-bar3 {
  animation-duration: 2s;
  animation-name: slideIn-3;
}

.bar-num-3 {
  padding: 0 1rem;
  color: black;
  height: var(--anim3-value);
}

@keyframes slideIn-3 {
  from {
    height: 0;
  }
  to {
    height: var(--anim3-value);
  }
}

.small-text {
  position: absolute;
  font-size: 3rem !important;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.5px;
  bottom: 6.5rem;
}
