:root {
  --anim1-value: 0%;
  --anim2-value: 0%;
  --anim3-value: 0%;
}

.container {
  height: 50vh;
  width: 80vw;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.2rem;
  display: flex;
  position: relative;
}

.bar-container {
  height: 90%;
  width: 24%;
  justify-content: center;
  align-items: center;
  padding-bottom: 9.6rem;
  display: flex;
}

.bar-background {
  height: 95%;
  width: 1.5rem;
  background-color: #d3d3d3;
  border-radius: 100px;
  align-items: flex-end;
  display: flex;
}

.bar-text {
  font-size: 3rem;
  font-weight: 600;
}

.bar-filler-1 {
  height: var(--anim1-value);
  width: 100%;
  background-color: #00bff2;
  border-radius: 100px;
}

.animate-bar1 {
  animation-name: slideIn-1;
  animation-duration: 2s;
}

@keyframes slideIn-1 {
  from {
    height: 0;
  }

  to {
    height: var(--anim1-value);
  }
}

.num-container {
  height: 95%;
  align-items: flex-end;
  display: flex;
}

.bar-num-1 {
  color: #000;
  height: var(--anim1-value);
  padding: 0 1rem;
}

.bar-filler-2 {
  height: var(--anim2-value);
  width: 100%;
  background-color: #00bff2;
  border-radius: 100px;
}

.animate-bar2 {
  animation-name: slideIn-2;
  animation-duration: 2s;
}

.bar-num-2 {
  color: #000;
  height: var(--anim2-value);
  padding: 0 1rem;
}

@keyframes slideIn-2 {
  from {
    height: 0;
  }

  to {
    height: var(--anim2-value);
  }
}

.bar-filler-3 {
  height: var(--anim3-value);
  width: 100%;
  background-color: #00bff2;
  border-radius: 100px;
}

.animate-bar3 {
  animation-name: slideIn-3;
  animation-duration: 2s;
}

.bar-num-3 {
  color: #000;
  height: var(--anim3-value);
  padding: 0 1rem;
}

@keyframes slideIn-3 {
  from {
    height: 0;
  }

  to {
    height: var(--anim3-value);
  }
}

.small-text {
  letter-spacing: .5px;
  font-weight: 700;
  line-height: 1.4;
  position: absolute;
  bottom: 6.5rem;
  font-size: 3rem !important;
}

/*# sourceMappingURL=index.064f814d.css.map */
